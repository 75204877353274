import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { GuideLink, ProductInfo } from '@t/components';
import { createProductSectionData } from '@/util';
import { feGuideUrl } from '@/constant';
import SEO from '@/components/SEO';
import GuideList from '@/components/guide/GuideList';
import SitemapSection from '@/components/main/sitemap/SitemapSection';
import createGA from '@/components/common/createGA';

import '@/query/feGuideQueryFragment';

interface IndexProps {
  location: {
    pathname: GuideLink;
  };
  data: {
    allEnGuides: { edges: EdgeNode[] };
    allGuides: { edges: EdgeNode[] };
    allProductsJson: { edges: { node: ProductInfo }[] };
  };
}

interface EdgeNode {
  node: {
    frontmatter: {
      id: string;
      title: string;
      description: string;
    };
  };
}

const Contents = styled.div`
  margin-top: 69px;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    padding: 0 20px;
    margin-top: 56px;
  }
`;

const Header = styled.div`
  padding: 60px 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e4e6e9;

  @media (max-width: 1200px) {
    padding: 60px 12.5%;
  }

  @media (max-width: 720px) {
    background-color: #fff;
    padding: 25px 0;
    border-bottom: 1px solid #ddd;
  }
`;

const Container = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 38px;
  margin-top: 0;
  margin-bottom: 0;
  color: #222;

  @media (max-width: 720px) {
    font-size: 24px;
  }
`;

const Slogan = styled.p`
  font-size: 16px;
  margin: 15px 0 0;
  color: #333;

  @media (max-width: 720px) {
    color: #222;
    margin-top: 15px;
    font-size: 17px;
    line-height: 1.5;
  }
`;

const FeGuideIndex: React.FC<IndexProps> = ({
  location,
  data: { allEnGuides, allGuides, allProductsJson },
}) => {
  const posts = location.pathname.indexOf(feGuideUrl.EN) > -1 ? allEnGuides : allGuides;
  const title = 'FE Guide';
  const description =
    'Get packed with compass and map for Front-End Javascript development voyage with FE Guide.';
  const sitemapData = createProductSectionData(allProductsJson);
  const ga = createGA('FE Guide - sitemap', 'click', 'sitemap');

  return (
    <>
      <Contents>
        <SEO title={title} description={description} />
        <Header>
          <Container>
            <Title>{title}</Title>
            <Slogan>{description}</Slogan>
          </Container>
        </Header>
        <GuideList posts={posts} />
      </Contents>
      <SitemapSection items={sitemapData} ga={ga} />
    </>
  );
};

export const pageQuery = graphql`
  query FeGuideIndex {
    ...allGuides
    ...allEnGuides

    # query for each section
    ...allProductsJson
  }
`;

export default FeGuideIndex;
