import React from 'react';
import styled from 'styled-components';

import GuideListItem from '@/components/guide/GuideListItem';
import { List as CommonList } from '@/components/common/List';

interface GuideListProps {
  posts: { edges: EdgeNode[] };
}

interface EdgeNode {
  node: {
    frontmatter: {
      id: string;
      title: string;
      description: string;
    };
  };
}

const Container = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    padding: 0;
  }
`;

const List = styled(CommonList)`
  padding: 30px 0 100px;

  & li:last-child {
    border-bottom: none;
  }

  @media (max-width: 720px) {
    padding: 0;
  }
`;

const GuideList: React.FC<GuideListProps> = ({ posts }) => (
  <Container>
    <List>
      {posts.edges.map(({ node }) => {
        const { id, title, description } = node.frontmatter;

        return <GuideListItem key={id} id={id} title={title} description={description} />;
      })}
    </List>
  </Container>
);

export default GuideList;
