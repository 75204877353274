import React from 'react';
import styled from 'styled-components';

import { feGuideUrl } from '@/constant';
import { ListItem } from '@/components/common/List';
import { BlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface GuideListItemProps {
  id: string;
  title: string;
  description: string;
}

const GuideItem = styled(ListItem)`
  position: relative;
  line-height: 1.5;
  padding: 30px 0;
  color: #222;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    padding: 20px 0;
  }
`;

const ItemContent = styled.div`
  margin-left: 0;

  &:hover span {
    color: #515ce6;
  }
`;

const Title = styled.span`
  font-size: 26px;
  font-weight: bold;
  color: #222;

  @media (max-width: 720px) {
    font-size: 17px;
  }
`;

const Description = styled.p`
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  color: #333;
  max-height: none;

  @media (max-width: 720px) {
    text-align: left;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 1.5;
    color: #777;
    margin-top: 7px;
  }
`;

const GuideListItem: React.FC<GuideListItemProps> = ({ id, title, description }) => {
  const url = `${feGuideUrl.PREFIX}/${id}`;
  const ga = createGA('FE Guide - detail', 'click', title);

  return (
    <GuideItem data-testid="guide-list">
      <ItemContent>
        <BlockLink type="internal" url={url} ga={ga}>
          <Title data-testid="title">{title}</Title>
          <Description data-testid="description">{description}</Description>
        </BlockLink>
      </ItemContent>
    </GuideItem>
  );
};

export default GuideListItem;
